import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { Box } from '../components/Box'
import { Product } from '../components/Product'
import ButtonHref from '../components/ButtonHref'
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ParentSEO from '../components/ParentSEO';
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import Category from '../components/Category'
import Heading from '../components/Heading'
import EmailArticle from '../components/EmailArticle'


 
export default function Landing() {


    
//  جميع سكوتراتنا تأتي مع ضمان شامل لضمان راحتك واطمئنانك، مما يجعل اكسترافا خيارك المثالي للتنقل بأمان وراحة.
  return ( 
    <ParentSEO title={"سكوتر كهربائي"} content={"نقدم لك أفضل أنواع السكوترات التي تمزج بين الرفاهية والأمان، لتناسب تنقلاتك اليومية بأسلوب يعكس رفاهيتك."}>
      {/* ======== Header  ========= */}
      <Header/>
          
           {/* ======== First Landing Display  ========= */}
      <div className='landing position-relative'> 
        <Banner/>  
      </div>  
        {/* <NewBanner/> */}
      
       {/* ======== Categories  ========= */}
       <Category/>   


      {/* ======== Banner Width  ========= */}
      <div className='sectionPadd'>
        <AdsBanner/> 
      </div>
     
      {/* ======== Products  ========= */}
      <Product sectionTitle='سكوتر كهربائي للكبار' desc="الأفضل"/>
      {/* ======== Services  ========= */}
      <div className='services'>
        <div className='boxes container' > 
            <Box imgIcon={"icon-fun.webp"} content="الرفاهية"/> 
            <Box imgIcon={"icon-long.webp"} content="الإستدامة"/> 
            <Box imgIcon={"icon-echo.webp"} content="الامان"/> 
            <Box imgIcon={"icon-after.webp"} content="ما بعد البيع"/> 
        </div> 
      </div> 

      {/* <EmailArticle/> */}
      
      {/* ======== Footer  ========= */}
      <Footer/>
    </ParentSEO>
  )
}


function Banner(){
    
return(
  <>
{/* <img rel='preload' loading='eager' className='position-absolute top-0 end-50 translate-middle-x' src="imgs/light.svg" alt="Light"/>  */}
<div className='text-center mt-2'>        
    <div className='banner'>
      <div className='welcome'>
      <span >اهلا بك في</span>
      </div>
      <div>

      <h2 className='logo-name'>إكسترافا</h2>
      </div>
      <div>

      <h2 className='logo-desc'>للرفاهية والأمان</h2>
      <h1 className='logo-desc fs-5'>سكوترات كهربائية</h1>
      </div>
    </div>

    {/* <link rel="preload" href="./imgs/landing.webp" as="image" type="image/webp"/> */}

        {/* <img rel="preload" style={{pointerEvents:"none",userSelect:"none"}} src='./imgs/landing.webp'  className='w-100 h-100' alt="للرفاهية والأمان"/>     */}
    <ButtonHref content='تسوق الآن'/>
</div>
  </>
)
}

function AdsBanner() {
     
return(
      <div className='banner container'>
          <img loading='lazy' style={{borderRadius:"var(--radius)",pointerEvents:"none",userSelect:"none"}} src='./imgs/banner.gif' className='w-100 h-100' alt="للرفاهية والأمان"/>    
          {/* <video width="100%"autoplay muted loop playsInline controls="play" >
    <source src="./imgs/vido-banner.mp4" type="video/mp4" />
  </video> */}
      </div>
  )
}




function NewBanner() {
  return(<>
    <div className='new-banner p-5'>
      {/* Start Text */}
      <div className='text-white text d-flex flex-column align-items-end'>
        <span>اهلا بك في اكسترافا</span>
        <h1>اكسترافا</h1>
        <h2>للرفاهية والامان</h2>
      </div>
      {/* Start Button */}
      <button className='btn btn-info text-whtie'>الذهاب الى التسوق</button>
    </div>  
  </>)
}
  