import logo from './logo.svg';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Landing from './pages/Landing';
import Policy from './pages/Policy';
import {Route, Router, Routes } from 'react-router-dom';
import Error404 from './pages/Error404';
import About from './pages/About';
import Contact from './components/Contact';
import CategoryProducts from './pages/CategoryProducts';
import Scooters from './pages/Scooters';
import Return from './pages/Return';
import { Details } from './pages/Details';
import { IdProvider } from './context/IdContext';
import SendTo from './pages/SendTo';
// import CategoryProducts from './components/CategoryProducts';

function App() {

  
  
  
  return (
    // <Landing />
    // <Policy/>
    // <Router>
      <Routes forceRefresh={true}>
        <Route path="/" element={<Landing/>} />
        <Route path="scooters" element={<Scooters/>} />
        <Route path="category" element={<CategoryProducts/>} />
        <Route path="about" element={<About/>} />
        <Route path="contact" element={<Contact/>} />
        <Route path="return" element={<Return/>} />
        <Route path="policy" element={<Policy/>} />
        <Route path="email" element={<SendTo/>} />
        
            <Route path="details" element={  <IdProvider><Details/></IdProvider>} />
      
      
        <Route path="*" element={<Error404/>} />
      </Routes>
    // </Router>
  );
}

export default App;
